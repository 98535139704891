import React from 'react'
import styled from 'styled-components'

import useCountdown from '../hooks/useCountDown'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

const StyledTime = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  .value {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  .name {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  @media (max-width: 768px) {
    gap: 5px;

    .value {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    .name {
      font-size: 1rem;
      line-height: 1rem;
    }
  }
`

const OpeningCountdown = ({ date, setIsOpen }) => {
  const countdownDate = date ?? ''
  const [days, hours, minutes, seconds] = useCountdown(countdownDate)

  React.useEffect(() => {
    if (days + hours + minutes + seconds <= 0) {
      setIsOpen(true)
    }
  }, [days, hours, minutes, seconds, setIsOpen])

  return (
    <Wrapper>
      <Time name="days" value={days} />
      <Time name="hours" value={hours} />
      <Time name="minutes" value={minutes} />
      <Time name="seconds" value={seconds} />
    </Wrapper>
  )
}

export default OpeningCountdown

const Time = ({ name, value }) => {
  return (
    <StyledTime>
      <p className="value">{value > 0 ? value : 0}</p>
      <p className="name">{name}</p>
    </StyledTime>
  )
}
